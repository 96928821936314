import { CodeComponentMeta, ComponentMeta, GlobalContextMeta } from '@plasmicapp/host';
import { ComponentType } from 'react';

import * as Components from '../components';
import { ComponentName } from './componentName';

export type ComponentMap = {
  [key in ComponentName | string]:
    | {
        component: ComponentType<any>;
        meta: ComponentMeta<any> | CodeComponentMeta<any>;
        isContext?: boolean;
      }
    | {
        component: ComponentType<any>;
        meta: GlobalContextMeta<any>;
        isContext: true;
      };
};

export const COMPONENT_MAP: ComponentMap = {
  [ComponentName.AccordionProvider]: {
    component: Components.AccordionProvider,
    meta: Components.ACCORDION_PROVIDER_META,
  },
  [Components.LINK_COMPONENT_NAME]: {
    component: Components.Link,
    meta: Components.LINK_META,
  },
  [ComponentName.AssetPosition]: {
    component: Components.AssetPosition,
    meta: Components.ASSET_POSITION_META,
  },
  [ComponentName.AssetPositionProvider]: {
    component: Components.AssetPositionProvider,
    meta: Components.ASSET_POSITION_PROVIDER_META,
  },
  [ComponentName.BillingCycle]: {
    component: Components.BillingCycle,
    meta: Components.BILLING_CYCLE_META,
  },
  [ComponentName.BillingCycleToggle]: {
    component: Components.BillingCycleToggle,
    meta: Components.BILLING_CYCLE_TOGGLE_META,
  },
  [ComponentName.BillingCycleTrigger]: {
    component: Components.BillingCycleTrigger,
    meta: Components.BILLING_CYCLE_TRIGGER_META,
  },
  [ComponentName.Button]: {
    component: Components.Button,
    meta: Components.BUTTON_META,
  },
  [ComponentName.ChilipiperForm]: {
    component: Components.ChilipiperForm,
    meta: Components.CHILIPIPER_FORM_META,
  },
  [ComponentName.Collapsible]: {
    component: Components.Collapsible,
    meta: Components.COLLAPSIBLE_META,
  },
  [ComponentName.CollapsibleContent]: {
    component: Components.CollapsibleContent,
    meta: Components.COLLAPSIBLE_CONTENT_META,
  },
  [ComponentName.CollapsibleTrigger]: {
    component: Components.CollapsibleTrigger,
    meta: Components.COLLAPSIBLE_TRIGGER_META,
  },
  [ComponentName.Dialog]: {
    component: Components.Dialog,
    meta: Components.DIALOG_META,
  },
  [ComponentName.DialogContent]: {
    component: Components.DialogContent,
    meta: Components.DIALOG_CONTENT_META,
  },
  [ComponentName.DialogDescription]: {
    component: Components.DialogDescription,
    meta: Components.DIALOG_DESCRIPTION_META,
  },
  [ComponentName.DialogTitle]: {
    component: Components.DialogTitle,
    meta: Components.DIALOG_TITLE_META,
  },
  [ComponentName.DialogTrigger]: {
    component: Components.DialogTrigger,
    meta: Components.DIALOG_TRIGGER_META,
  },
  [ComponentName.ErrorMessage]: {
    component: Components.ErrorMessage,
    meta: Components.ERROR_MESSAGE_META,
  },
  [ComponentName.Form]: {
    component: Components.Form,
    meta: Components.FORM_META,
  },
  [ComponentName.FormRow]: {
    component: Components.FormRow,
    meta: Components.FORM_ROW_META,
  },
  [ComponentName.HomeHero]: {
    component: Components.HomeHero,
    meta: Components.HOME_HERO_META,
  },
  [ComponentName.HomeAssets]: {
    component: Components.HomeAssets,
    meta: Components.HOME_ASSETS_META,
  },
  [ComponentName.Input]: {
    component: Components.Input,
    meta: Components.INPUT_META,
  },
  [ComponentName.ProgressBar]: {
    component: Components.ProgressBar,
    meta: Components.PROGRESS_BAR_META,
  },
  [ComponentName.RouteForm]: {
    component: Components.RouteForm,
    meta: Components.ROUTE_FORM_META,
  },
  [Components.SLIDE_NAME]: {
    component: Components.Slide,
    meta: Components.SLIDE_META,
  },
  [Components.SLIDER_NAME]: {
    component: Components.Slider,
    meta: Components.SLIDER_META,
  },
  [ComponentName.SearchParamText]: {
    component: Components.SearchParamText,
    meta: Components.SEARCH_PARAM_TEXT_META,
  },
  [ComponentName.Tabs]: {
    component: Components.Tabs,
    meta: Components.TABS_META,
  },
  [ComponentName.TabsContent]: {
    component: Components.TabsContent,
    meta: Components.TABS_CONTENT_META,
  },
  [ComponentName.TabsList]: {
    component: Components.TabsList,
    meta: Components.TABS_LIST_META,
  },
  [ComponentName.TabsList]: {
    component: Components.TabsList,
    meta: Components.TABS_LIST_META,
  },
  [ComponentName.TabsTrigger]: {
    component: Components.TabsTrigger,
    meta: Components.TABS_TRIGGER_META,
  },
  [ComponentName.Flyer]: {
    component: Components.Flyer,
    meta: Components.FLYER_META,
  },
};
